/* navbar.scss */
.navbar {
    width: 100%;
    padding: 10px 10px 0 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .logo {
        display: flex;
        align-items: center;
        font-weight: bold;
        gap: 10px;
    }

    .icons {
        display: flex;
        align-items: center;
        gap: 20px;
    }

    .notification {
        position: relative;

        span {
            background-color: red;
            color: white;
            width: 16px;
            height: 16px;
            border-radius: 50%;
            position: absolute;
            top: -10px;
            right: -10px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 12px;
        }
    }

    /* Add the cursor pointer style to the .icon class */
    .icon {
        cursor: pointer; /* Add this line */
        padding: 0 !important;
    }

    .user {
        display: flex;
        align-items: center;
        gap: 5px;

        img {
            width: 20px;
            height: 20px;
            border-radius: 50%;
            object-fit: cover;
        }
    }
}
