.autocomplete {

    .MuiButton-root {
        padding-right: 0px !important;
    }
    .MuiAutocomplete-inputRoot {
        padding-right: 0px !important;
    }
}

/* AutoCompleteTextField.scss */

.autocomplete .MuiAutocomplete-option {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  
  .autocomplete .MuiAutocomplete-option .MuiTypography-root {
    color: #1976d2; /* MUI primary color */
    font-weight: 500;
    text-decoration: underline;
    cursor: pointer;
  }
  
  .autocomplete .MuiAutocomplete-option:hover {
    background-color: rgba(25, 118, 210, 0.08);
  }
  