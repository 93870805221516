/* ResponsiveTabs.css */

.responsive-tabs {
    border-bottom: 1px solid #ccc;
}

.responsive-tab {
    font-weight: bold !important;
    text-transform: none !important;
    min-width: 100px;
    padding: 12px;
}

.responsive-tab-selected {
    color: var(--mui-palette-primary-main);
    font-weight: bold !important;
}

.responsive-tab-hover:hover {
    background-color: #e3f2fd;
}

/* .responsive-tab-divider:not(:last-of-type) {
    border-right: 1px solid #ccc;
} */

.responsive-tab-content {
    padding-top: 16px;
    background-color: #fff;
    border-top: none;

    /* .MuiGrid-root {

        .MuiGrid-item{
            padding-left: 0px !important;
        }
    } */
    /* .MuiGrid-root {
        margin-left: 0px;
    } */
}


