@import '../../../styles/variables.scss';

.menu {
    .MuiAccordion-root {
        box-shadow: none;
        margin: 0 !important;
        &:before {
            display: none;
        }

        .MuiAccordionSummary-root {
            display: flex;
            align-items: center;
            gap: 10px;
            border-radius: 5px;

            &:hover {
                background-color: $soft-bg;
            }

            min-height: 0 !important; // Reduce the minimum height
            padding: 0!important; // Remove padding
            margin: 5px !important; // Remove margin
    
            .MuiAccordionSummary-content {
                margin: 10px !important; // Remove margin
            }

            .MuiTypography-root {
                font-size: 12px;
                font-weight: bold; // Bold text for menu
                color: $soft-color;
            }
            .Mui-expanded{
                min-height: 0 !important;
            }
        }

        .MuiAccordionDetails-root {
            display: flex;
            flex-direction: column;
            gap: 10px;
            padding: 0 0 0 10px;

            .MuiList-root{
                padding: 0px;
            }
        }
    }
  .item {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-bottom: 20px;

    .title {
      font-size: 12px;
      font-weight: 200;
      color: $soft-color;
    }

    .listItem {
      display: flex;
      align-items: center;
      gap: 10px;
      padding: 10px;
      border-radius: 5px;

      &:hover {
        background-color: $soft-bg;
      }
    }
  }

  .iconimg {
    min-width: 36px !important;
  }

  .MuiPaper-root {
    position: relative !important;
    background-color: white;
  }
}
