.dataTable {
    .dataGrid {
        background-color: white;
        .MuiDataGrid-toolbarContainer {
            flex-direction: row-reverse;
        }
        .MuiDataGrid-row:hover {
            cursor: pointer;
        }
        img {
            width: 32px;
            border-radius: 50%;
            height: 32px;
            object-fit: cover;
            margin-top: 10px;
        }
        /* Adjust or remove this rule */
        .MuiDataGrid-virtualScrollerContent {
          //  min-height: 50px !important; 
            max-height: 500px !important;

            min-height: auto !important;
        }
    }
    /* Ensure the display does not cause unintended layout issues */
    display: block;
    max-height: 400px;
    overflow-y: auto;
}
