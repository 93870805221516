@import './variables.scss';

:root {
    --base-font-size: 12px;
    --heading-font-size: 24px;
    --subheading-font-size: 12px;
    --small-font-size: 12px;
}

body {
    font-size: var(--base-font-size) !important;
}

h1 {
    font-size: var(--heading-font-size) !important;
    font-weight: bold !important;
}

h2, h3, h4, h5, h6 {
    font-size: var(--subheading-font-size) !important;
    font-weight: bold !important;
}

.small-text {
    font-size: var(--small-font-size) !important;
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

a {
    text-decoration: none;
    color: inherit;
}

.main {
    font-family: "inter", sans-serif;
    background-color: $main-bg;
    color:$main-color;
}

.container {
    display: flex;
    padding-bottom: 10px;
//    height: calc(100vh - 80px);
}

.menuContainer {
   // width: 25%;
   // padding: 5px 20px;
   // border-right: 2px solid $soft-bg;
    background-color: #ffffff;
    border-radius: 8px; 
   // box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); 
    margin-right: 5px;
    padding-bottom: 20px;
    max-height: calc(100vh - 64px);
   // overflow-y: auto;
    //overflow-x: hidden
}

.contentContainer {
    background-color: #ffffff;
    width: 100%;
    border-radius: 8px; 
   // box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); 
    overflow-x: hidden;
    padding-bottom: 20px;
}

.MuiListItemText-primary {
    font-size: var(--base-font-size) !important; /* Use base font size for primary text */
}

.MuiListItemText-secondary {
    font-size: var(--subheading-font-size) !important; /* Use subheading font size for secondary text */
}

.MuiListItemIcon{
    min-width: none ;
    padding: 3px;
}

.icon {
    width: 20px;
    height: 20px;
}

.LinesGrid{
    border-top: 5px solid #1976d2;
    padding-top: 10px;
}
.headersGrid{
    padding-bottom: 20px;
}
/*
background-color: #ffffff; /* White background for light theme 
padding: 10px; /* Adjust padding for more spacing 
border-radius: 8px; /* Rounded corners for card-like appearance 
box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth 
border: 1px solid #e0e0e0; /* Light gray border 
margin: 10px; /* Optional: add margin for spacing between elements */